/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body
{
    font-family: 'Poppins', sans-serif !important;
    --ion-default-font: 'Poppins', sans-serif !important;
}

[home_search]
{

ion-searchbar  
{

	padding:0px;
    --box-shadow: none;
	.searchbar-input-container
	{
		height: 100%;
	}
	.searchbar-search-icon
	{
		top: 0px;
		color: var(--ion-color-primary);
		bottom: 0px;
		margin: auto;
		width: 18px;
		height: 18px;
	}
	input.searchbar-input
	{
	    font-size: 14px;
		font-weight: 500;
	    line-height: 28px!important;
	    background: var(--ion-color-grey);
	    color:#353843;
        border-radius: 6px;
        height: 100%;
	    --placeholder-opacity: 1;
        padding-inline-start: 40px!important;
        padding-inline-end: 35px!important;
	}
}
}

.md{
  ion-button {
    min-height: 52px;
    --box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.01);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.01);
    border-radius: 100px;
    --border-radius:100px;
    font-size: 16px !important;
  }

  ion-header{
    ion-toolbar{
      --padding-start: 0 !important;
    --padding-end: 0 !important;
    }
  }

  .footer-md::before
  {
    display: none !important;
  }

}

.RatingModal {
  --border-radius: 20px;
  --width: calc(100% - 30px);
  --max-height: 380px;
}

.iconswidth
{
  width: 16px;
}

.md{
  ion-header{
    ion-toolbar{
      ion-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
  }
}
.addRatingModal {
    --border-radius: 20px;
    --width: calc(100% - 30px);
    --max-height: 500px;
}
ion-modal
{
	&.addnewcard-page
	{
		--border-radius: 30px 30px 0px 0px;
		--max-height: 410px;
		align-items: flex-end;
	}
}
.quots .swiper-pagination-bullet {
    width: 25px;
    border-radius: 50px !important;
}